export const footerLinks = [
  {
    title: 'biuro nieruchomości',
    titleEN: 'REAL ESTATE AGENCY',
    titleUA: 'АГЕНЦІЯ НЕРУХОМОСТІ',
    links: [
      {
        label: 'informacje - biuro nieruchomości',
        labelEN: 'REAL ESTATE AGENCY',
        labelUA: 'АГЕНЦІЯ НЕРУХОМОСТІ',
        url: '/biuro-nieruchomosci/informacje',
      },
      {
        label: 'sprzedaż nieruchomości',
        labelEN: 'Sell your property',
        labelUA: 'Продаж нерухомості',
        url: '/biuro-nieruchomosci/sprzedaz-nieruchomosci',
      },
      {
        label: 'zakup nieruchomosci',
        labelEN: 'Purchase property',
        labelUA: 'Купівля нерухомості',
        url: '/biuro-nieruchomosci/zakup-nieruchomosci',
      },
      {
        label: 'wynajem nieruchomosci',
        labelEN: 'Property lease',
        labelUA: 'Оренда нерухомості',
        url: '/biuro-nieruchomosci/wynajem-nieruchomosci',
      },
      {
        label: 'sprzedaż działek',
        labelEN: 'sale of plots',
        labelUA: 'продаж ділянок',
        url: '/biuro-nieruchomosci/sprzedaz-dzialek',
      },
      {
        label: 'inwestycje w nieruchomości',
        labelEN: 'real estate investments',
        labelUA: 'інвестиції в нерухомість',
        url: '/biuro-nieruchomosci/inwestycja-w-nieruchomosci',
      },
      {
        label: 'doradztwo kredytowe',
        labelEN: 'Credit advisory',
        labelUA: 'Консультація з кредитних питань',
        url: '/biuro-nieruchomosci/doradztwo-kredytowe',
      },
      {
        label: 'oferty',
        labelEN: 'offers',
        labelUA: 'пропозиції',
        url: 'https://oferty.gracehome.pl/oferty',
      },
    ],
  },
  {
    title: 'legalizacja pobytu',
    titleEN: 'LEGALISE YOUR STAY',
    titleUA: 'ЛЕГАЛІЗАЦІЯ ПЕРЕБУВАННЯ',
    links: [
      {
        label: 'informacje - legalizacja pobytu',
        labelEN: 'LEGALISE YOUR STAY',
        labelUA: 'ЛЕГАЛІЗАЦІЯ ПЕРЕБУВАННЯ',
        url: '/legalizacja-pobytu/informacje',
      },
      {
        label: 'karta pobytu',
        labelEN: 'RESIDENCE CARD',
        labelUA: 'КАРТА ПЕРЕБУВАННЯ (ДОЗВІЛ)',
        url: '/legalizacja-pobytu/karta-pobytu',
      },
      {
        label: 'obywatelstwo RP',
        labelEN: 'BECOMING A CITIZEN OF THE REPUBLIC OF POLAND',
        labelUA: 'ВИЗНАННЯ ПОЛЬСЬКИМ ГРОМАДЯНИНОМ',
        url: '/legalizacja-pobytu/obywatelstwo-rp',
      },
      {
        label: 'odwołania od negatywnych decyzji',
        labelEN: 'APPEALS AGAINST NEGATIVE ADMINISTRATIVE DECISIONS',
        labelUA: 'ОСКАРЖЕННЯ НЕГАТИВНИХ РІШЕНЬ',
        url: '/legalizacja-pobytu/odwolania-od-negatywnych-decyzji',
      },
    ],
  },
  {
    title: 'informacje',
    titleEN: 'information',
    titleUA: 'інформації',
    links: [
      {
        label: 'Grace Home Fundacja',
        labelEN: 'Grace Home Foundation',
        labelUA: 'Grace Home Foundation',
        url: '/biuro-nieruchomosci/fundacja',
      },
      {
        label: 'Grace Home Tours',
        labelEN: 'Grace Home Tours',
        labelUA: 'Grace Home Tours',
        url: '/biuro-nieruchomosci/tours',
      },
      {
        label: 'kariera w Grace Home',
        labelEN: 'CAREER AT GRACE HOME ',
        labelUA: "КАР'ЄРА В GRACE HOME",
        url: '/wspolpraca/kariera',
      },
      {
        label: 'zarabiaj z Grace Home',
        labelEN: 'EARN WITH GRACE HOME',
        labelUA: 'ЗАРОБЛЯЙТЕ З GRACE HOME',
        url: '/wspolpraca/zarabiaj-z-grace-home',
      },
      {
        label: 'aktualności',
        labelEN: 'NEWS',
        labelUA: 'новини',
        url: '/aktualnosci',
      },
      {
        label: 'polityka prywatności',
        labelEN: 'privacy policy',
        labelUA: 'політика конфіденційності',
        url: '/polityka-prywatnosci',
      },
    ],
  },
];
