export const navlinks = [
  {
    page: 'strona główna',
    pageEN: 'Home',
    pageUA: 'ГОЛОВНА СТОРІНКА',
    url: '/',
  },
  {
    page: 'biuro nieruchomości',
    pageEN: 'REAL ESTATE AGENCY',
    pageUA: 'АГЕНЦІЯ НЕРУХОМОСТІ',
    links: [
      {
        label: 'informacje',
        labelEN: 'information',
        labelUA: 'інформації',
        url: '/biuro-nieruchomosci/informacje',
      },
      {
        label: 'sprzedaż nieruchomości',
        labelEN: 'SELL YOUR PROPERTY',
        labelUA: 'Продаж нерухомості',
        url: '/biuro-nieruchomosci/sprzedaz-nieruchomosci',
      },
      {
        label: 'zakup nieruchomosci',
        labelEN: 'Purchase property',
        labelUA: 'Купівля нерухомості',
        url: '/biuro-nieruchomosci/zakup-nieruchomosci',
      },
      {
        label: 'wynajem nieruchomosci',
        labelEN: 'Property lease',
        labelUA: 'Оренда нерухомості',
        url: '/biuro-nieruchomosci/wynajem-nieruchomosci',
      },
      {
        label: 'sprzedaż działek',
        labelEN: 'sale of plots',
        labelUA: 'продаж ділянок',
        url: '/biuro-nieruchomosci/sprzedaz-dzialek',
      },
      {
        label: 'inwestycje w nieruchomości',
        labelEN: 'real estate investments',
        labelUA: 'інвестиції в нерухомість',
        url: '/biuro-nieruchomosci/inwestycja-w-nieruchomosci',
      },
      {
        label: 'doradztwo kredytowe',
        labelEN: 'Credit advisory',
        labelUA: 'Консультація з кредитних питань',
        url: '/biuro-nieruchomosci/doradztwo-kredytowe',
      },
      {
        label: 'Grace Home Fundacja',
        labelEN: 'Grace Home Foundation',
        labelUA: 'Фонд Grace Home',
        url: '/biuro-nieruchomosci/fundacja',
      },
      {
        label: 'Grace Home Tours',
        labelEN: 'Grace Home Tours',
        labelUA: 'Тури Grace Home',
        url: '/biuro-nieruchomosci/tours',
      },
    ],
  },
  {
    page: 'oferty',
    pageEN: 'OFFERS',
    pageUA: 'ПРОПОЗИЦІЇ',
    url: 'https://oferty.gracehome.pl/oferty',
  },
  {
    page: 'legalizacja pobytu',
    pageEN: 'LEGALISE YOUR STAY',
    pageUA: 'ЛЕГАЛІЗАЦІЯ ПЕРЕБУВАННЯ',
    links: [
      {
        label: 'informacje',
        labelEN: 'information',
        labelUA: 'інформації',
        url: '/legalizacja-pobytu/informacje',
      },
      {
        label: 'karta pobytu',
        url: '/legalizacja-pobytu/karta-pobytu',
        labelEN: 'RESIDENCE CARD',
        labelUA: 'КАРТА ПЕРЕБУВАННЯ (ДОЗВІЛ)',
      },
      {
        label: 'obywatelstwo rp',
        url: '/legalizacja-pobytu/obywatelstwo-rp',
        labelEN: 'BECOMING A CITIZEN OF THE REPUBLIC OF POLAND',
        labelUA: 'ВИЗНАННЯ ПОЛЬСЬКИМ ГРОМАДЯНИНОМ',
      },
      {
        label: 'odwołania od negatywnych decyzji',
        labelEN: 'APPEALS AGAINST NEGATIVE ADMINISTRATIVE DECISIONS',
        labelUA: 'ОСКАРЖЕННЯ НЕГАТИВНИХ РІШЕНЬ',
        url: '/legalizacja-pobytu/odwolania-od-negatywnych-decyzji',
      },
    ],
  },
  {
    page: 'współpraca',
    pageEN: 'WORK WITH US',
    pageUA: 'СПІВПРАЦЯ',
    links: [
      {
        label: 'kariera w grace home',
        url: '/wspolpraca/kariera',
        labelEN: 'CAREER AT GRACE HOME ',
        labelUA: "КАР'ЄРА В GRACE HOME",
      },
      {
        label: 'zarabiaj z grace home',
        url: '/wspolpraca/zarabiaj-z-grace-home',
        labelEN: 'EARN WITH GRACE HOME',
        labelUA: 'ЗАРОБЛЯЙТЕ З GRACE HOME',
      },
    ],
  },
  {
    page: 'aktualności',
    pageEN: 'NEWS',
    pageUA: 'новини',
    url: '/aktualnosci',
  },
  {
    page: 'kontakt',
    pageEN: 'CONTACT',
    pageUA: 'контакт',
    url: '/kontakt',
  },
];
